.box {
  position: relative;
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 12px;
  border: 1px solid #e4e5e6;
  padding: 15px 20px;
  overflow: hidden;
  &.no-scroll {
    overflow-y: hidden;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 8px;
    .left-side {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      img {
        width: 30px;
        height: 30px;
        margin-right: 15px;
      }
      h3 {
        margin-bottom: 0 !important;
        margin-top: 0 !important;
      }
    }
    .hide-box {
      pointer-events: all !important;
      position: relative;
      z-index: 3;
      width: 25px;
      height: 15px;
      .checkbox {
        margin: 0 !important;
      }
    }
  }
  .calendar-container-width {
    width: 50%;
    .deadline-proposition {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      .calendar-deadline {
        width: 100%;
        .add-new-deadline {
          width: 50px;
          height: 30px;
          font-family: 'Open Sans', sans-serif;
          font-weight: 300;
          color: #212529;
          border-radius: 50px;
          font-size: 20px;
          background-color: white;
          border: 1px solid #e4e5e6;
          margin-right: auto;
        }
      }
      .chosen-date {
        width: 90px;
        height: 30px;
        border-radius: 50px;
        background-color: $variant-blue;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-family: 'Open Sans', sans-serif;
        font-weight: 700;
        font-size: 12px;
        margin: 0 !important;
        position: relative;
        cursor: pointer;
        .delete-tooltip {
          position: absolute;
          bottom: 85%;
          left: calc(50% - 40px);
          width: 80px;
          height: 27px;
          background-color: #4d4d4d;
          border-radius: 50px;
          color: white;
          padding: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          opacity: 0;
          cursor: pointer;
          transition: opacity 400ms ease-in-out;
          animation: visible 0.4s ease-in-out;
          pointer-events: none;
          &.tooltipVisible {
            opacity: 1; /* Full opacity when tooltip is supposed to be visible */
            pointer-events: all;
          }
        }
      }
    }
  }
  .custom-radio {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    margin-bottom: 0px;
    margin-right: 10px;
    font-family: 'Open Sans', sans-serif;
    white-space: normal;
    font-size: 12px;
    font-weight: 400;
    color: $black;

    &.language-radio {
      margin-right: 30px;
      margin-bottom: 0;
    }
  }

  .custom-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .custom-radio .custom-radio-checkmark {
    position: relative;
    min-width: 15px;
    min-height: 15px;
    width: 15px;
    height: 15px;
    border: 1px solid #e4e5e6; /* Green border */
    border-radius: 50%;
    display: inline-block;
    margin-right: 15px;
    margin-bottom: 0 !important;
    box-sizing: border-box;
    transition:
      background-color 0.3s,
      border-color 0.3s;
  }

  .custom-radio input:checked + .custom-radio-checkmark {
    background-color: $main-green; /* Green background when checked */
    border-color: $main-green;
  }

  .custom-radio input:checked + .custom-radio-checkmark::after {
    display: block;
  }

  .fields {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: auto;
    overflow-y: auto;
    &.column-2 {
      flex-direction: row;
      .fields-column {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
    }
  }
  &.has-children {
    .fields {
      flex-direction: column;
      justify-content: space-between;
    }
  }
  &.hidden {
    pointer-events: none;
    display: block !important;
    &::after {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      background: #ffffff;
      opacity: 0.8;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      border-radius: 5px;
    }
  }
  display: flex;
  &.box-size-1 {
    min-width: 544px;
    width: 100%;
    height: 360px;
    .fields {
      height: 280px;
    }
  }
  &.box-size-2 {
    min-width: 544px;
    width: 100%;
    height: 236px;
    .fields {
      height: 165px;
    }
  }
  &.box-size-3 {
    min-width: 544px;
    width: 100%;
    height: 112px;
    overflow: visible !important;
    .fields {
      overflow: unset;
    }
  }
  &.order-1 {
    order: 1;
  }
  &.order-2 {
    order: 2;
  }
  &.order-3 {
    order: 3;
  }
  &.order-4 {
    order: 4;
  }
  .box-wrapper {
    position: relative;
    padding: 0;
    height: 100%;
    display: flex;
    width: 100%;
    .calender-data {
      background-color: #f4f4f4;
      border: 0;
    }
  }
  .switch-container {
    display: inline-block;
    width: 25px;
    height: 16px;
    position: relative;
    cursor: pointer;
  }

  .switch {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    background-color: #ccc;
    position: relative;
    transition: background-color 0.3s;
  }

  .switch.on {
    background-color: $main-green;
  }

  .switch.off {
    background-color: #ccc;
  }

  .switch-button {
    width: 14px;
    height: 14px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    top: 1px;
    left: 1px;
    transition: left 0.3s ease-in-out;
  }

  .switch.on .switch-button {
    left: 10px;
  }

  .switch.off .switch-button {
    left: 2px;
  }

  .checkbox {
    position: relative;
    display: flex;
    align-items: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: 300;
    color: $black;
    padding-left: 0 !important;
    margin: 0 !important;
    cursor: pointer;
    .checkmark {
      height: 6px;
      width: 6px;
      background: #ffffff;
      border: 0.5px solid #d9d9d9;
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
      border-radius: 50%;
      margin-right: 10px;
      position: absolute;
      top: 50%;
      left: 2px;
      transform: translateY(-50%);
    }
    input[type='checkbox'] {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    input[type='checkbox']:checked ~ .checkmark {
      background: $main-green;
      border: 0.5px solid #ffffff;
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
    }
  }
  h3 {
    margin-bottom: 5px;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: 700;
    color: $black;
  }
  span {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: 400;
    // color: $black;
    margin-bottom: 6px;
  }
  .logo {
    width: 30px;
    margin-right: 16px;
    img {
      max-width: 100%;
    }
  }
  .right-col {
    width: 100%;
  }
  .content {
    position: relative;
    padding: 0;
    height: 100%;
    display: flex;
    height: auto;
  }
  &.not-validated {
    border: 1px solid $danger;
  }
}
