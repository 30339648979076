.team-container {
  padding: 12px 9px;
  position: relative;
  height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  .filter-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 8px;
    .filter-bar {
      margin: 0;
    }
  }
  .row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px !important;
    &::before,
    &::after {
      display: none !important;
    }
  }

  .box {
    box-sizing: border-box;
    position: relative;
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 0 !important;
    padding: 15px;
    height: 442px;
    .serchbar {
      margin: 0 !important;
      margin-left: auto !important;
      input {
        width: 150px;
      }
    }
    .row {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
    .row-border {
      position: relative;
      z-index: 5;
      &::after,
      &::before {
        display: none;
      }
    }
    &.box-client {
      top: 0;
      height: 100%;
    }
    .droppable-wrapper {
      position: relative;
    }
    &.designer,
    &.box-GRAPHIC_DESIGNER,
    &.box-CLIENT {
      height: calc(100vh - 70px);
    }
  }
  .key-visual-box-container {
    .row {
      justify-content: flex-start;
    }
  }
  .box-title {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    height: 30px;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: $black;
    margin: 0 !important;
    margin-bottom: 18px !important;
  }
  .select-box-container {
    flex-direction: row !important;
    margin-bottom: 30px;
    justify-content: center;
    .custom-input {
      width: 300px;
      border: 1px solid $border-grey;
      border-radius: 8px;
    }
  }
}

.z-index-first {
  z-index: 666;
}

.show-element {
  display: flex;
}

.team-bg-shadow {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(0.5px);
  z-index: 500;
}
.assigned-project-box-container-around {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  border-radius: 10px;
  border: 1px solid $border-grey;
  background-color: #fcfcfc;
  transition: 0.2s ease-out;
  height: 130px;
  width: 300px;
  position: relative;
  padding: 7px 15px;
  &:nth-child(2n + 2) {
    margin-right: 0;
  }
  &:hover {
    background-color: #f6f6f6;
    box-shadow: 0px 0px 19px -15px rgba(66, 68, 90, 1);
  }
  &.show {
    border: 1px solid $border-green;
    &:hover {
      background-color: #fcfcfc;
      box-shadow: unset;
    }
    .action {
      display: flex !important;
      align-items: flex-end;
    }
  }
  .assigned-project-box-container {
    cursor: pointer;
    p {
      font-family: 'Open Sans', sans-serif;
      font-style: normal;

      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #373939;
      &.status {
        text-transform: uppercase;
      }
      &.action-name {
        font-weight: 700;
        margin-bottom: 6px !important;
      }
    }

    .top-section {
      display: flex;
      justify-content: space-between;
      margin-bottom: 11px;
      .status-wrapper {
        display: flex;
        align-items: center;
        .status-color {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          margin-right: 5px;
        }
      }

      .date-wrapper {
        display: flex;
        align-items: center;
        svg {
          margin-right: 5px;
        }
      }
    }

    .bottom-section {
      display: flex;
      justify-content: space-between;
      .start-project-worklog {
        font-family: 'Open Sans', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        width: 82px;
        height: 25px;
        border: 1px solid #373939;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.2s ease-out;
        &:hover {
          background: #e7e7e7;
        }
      }
      .action-name {
        margin-bottom: 6px !important;
      }
      .action {
        display: none;
      }
      .date {
        p {
          font-weight: normal;
        }
      }
    }
  }
  .settings-icon {
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.2s ease-out;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    &:hover {
      background-color: #f0f0f0;
    }
  }

  .details {
    position: absolute;
    left: 0;
    right: 5px;
    bottom: -100%;
    width: 100%;
    background: #ffffff;
    border-radius: 10px;
    padding: 3px;
    border-radius: 9px;
    overflow: hidden;
    transition: 0.3s linear;
    border-top: 1px solid #e4e5e6;
    .details-content {
      position: relative;
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 8px;
      border-radius: 7px;
      .small-select {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 300;
        font-size: 8px;
        line-height: 10px;
        color: rgba(0, 0, 0, 0.3);
        background: #ffffff;
        border: 0.7px solid #d9d9d9;
        border-radius: 3px;
        height: 15px;
        width: 100%;
      }

      .row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        .button-wrapper {
          cursor: pointer;
          position: relative;
          overflow: hidden;
          display: flex;
          align-items: center;
          border: 1px solid #cccccc;
          border-radius: 20px;
          max-width: 30px;
          height: 30px;
          margin-right: 6px;
          transition: 0.5s linear;
          &:hover {
            max-width: 500px;
            .icon {
              &::after {
                filter: invert(81%) sepia(12%) saturate(1472%) hue-rotate(55deg)
                  brightness(87%) contrast(83%);
              }
            }
          }
        }
        .icon {
          position: absolute;
          top: 0;
          left: 0;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          border: 1px solid #cccccc;
          border-left: 0;
          border-top: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          &::after {
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            transform: translateX(-1px);
          }

          &.capture {
            &::after {
              background-image: url('../assets/undo.svg');
            }
          }
          &.archive {
            &::after {
              background-image: url('../assets/address-card.svg');
            }
          }

          // &.close-btn {
          //   &::after {
          //     background-image: url("../assets/closse-icon.svg");
          //     transform: translateX(0);
          //   }
          // }
        }
        .close-btn {
          cursor: pointer;
          border-left: 1px solid #cccccc;
          border-top: 1px solid #cccccc;
          position: absolute;
          left: unset;
          top: unset;
          right: 8px;
        }
        .extended-text {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 2px 7px;
          padding-left: 34px;
          span {
            margin: 0;
            white-space: nowrap;
            color: $black;
            font-family: 'Open Sans', sans-serif;
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            text-transform: uppercase;
          }
        }
      }
    }

    &.details-height {
      bottom: 0;
    }
  }
}
.title-list {
  width: 78px;
  position: relative;
  margin-top: -2px;
  .small-select-title-note {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 18px;
    text-align: right;
    color: $black;
  }
  .small-select-title-move {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 18px;
    text-align: right;
    color: $black;
  }
}

.input-wrapper {
  width: 137px;
  padding-left: 8px;
  textarea {
    width: 100%;
    height: 51px;
    margin-top: 4px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 8px;
    line-height: 10px;
    color: rgba(0, 0, 0, 0.3);
    background: #ffffff;
    border: 0.7px solid #d9d9d9;
    border-radius: 3px;
  }
}
.title-height {
  height: 41px;
  margin-bottom: 22px !important;
}

.arrows {
  width: 20px;
  height: 20px;
  border-color: $black;
  position: absolute;
  top: 50%;
  margin-top: -14px;
}

.prev {
  border-bottom: 2px solid;
  border-left: 2px solid;
  transform: rotate(45deg);
  left: -25px;
}

.next {
  border-bottom: 2px solid;
  border-left: 2px solid;
  transform: rotate(-135deg);
  right: -25px;
}

.page_component_wrapper {
  height: 100%;
  width: 100%;
  position: relative;
  // background: #ffffff;
  overflow: hidden;
  .top_border {
    height: 11px;
    background: linear-gradient(
      180deg,
      #f4f4f4 0%,
      rgba(244, 244, 244, 0) 47.72%
    );
    position: absolute;
    top: 0;
    left: -10px;
    right: -10px;
  }
  .bookmark-wrapper {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    align-items: center;
    text-align: center;
    color: $black;

    padding: 5px 0px 0px 20px;
    .variants-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    .right-side-content {
      display: flex;
      justify-content: center;
      align-items: center;
      .check-history {
        margin-right: 10px;
        height: 15px;
        width: 15px;
        display: flex;
        cursor: pointer;
        svg {
          height: 15px;
          width: 15px;
        }
      }
    }
    // border-bottom: 2px solid #f4f4f4;
    .order-id {
      font-family: 'Open Sans', sans-serif;
      font-size: 12px;
      font-weight: 300;
      text-transform: uppercase;
    }
    .section-title {
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      color: $black;
      margin: 0 !important;
      white-space: nowrap;
    }
    .version {
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 15px;
      display: flex;
      align-items: center;
      margin: 0;
      color: $black;
      order: 2;
    }
    .next_button {
      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 12px;
      display: flex;
      align-items: center;
      text-align: center;

      color: rgba(0, 0, 0, 0.1);
    }
    .page_bookmark {
      width: 100px;
      height: 30px;
      border-radius: 50px;
      background: #fff;
      border: 1px solid $variant-blue;
      color: $variant-blue;
      z-index: 0;
      margin-right: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 13px;
      font-family: 'Open Sans', sans-serif;
      font-weight: 400;
      cursor: pointer;
      &:hover {
        background: $variant-blue-hover;
        color: white;
        border: 1px solid $variant-blue-hover;
      }
      &:active {
        background: $variant-blue;
        color: white;
        border: 1px solid $variant-blue;
      }
      &.has-correction {
        border: 1px solid $has-correction-hover;
        color: $has-correction-hover;
        &:hover {
          background: $has-correction-hover;
          color: white;
          border: 1px solid $has-correction-hover;
        }
        &:active {
          background: $has-correction;
          color: white;
          border: 1px solid $has-correction;
        }
      }
      &.selected_page {
        background: $variant-blue;
        color: #fff;
        border: none;
        &.has-correction {
          border: 1px solid $has-correction;
          background: $has-correction;
          color: white !important;
        }
      }
      &.warning {
        background: linear-gradient(
          180deg,
          rgba(205, 23, 25, 0.1) 0%,
          rgba(255, 255, 255, 0.1) 100%
        );
        .wrapper-hider {
          color: #cd1719;
        }
      }
    }
  }

  .text-section {
    display: flex;
    display: flex;
    margin-right: 20px;
  }

  .content {
    width: 100%;
    padding: 15px 35px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
    overflow-y: auto;
    height: calc(100% - 60px) !important;
    .title-wrapper {
      margin-top: 54px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      .under-title {
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        color: $black;
      }
      .border-title {
        width: 85%;
        margin-left: 15px;
        background-color: #f4f4f4;
        height: 1px;
      }
    }
  }

  &.extended {
    .bookmark-wrapper {
      padding-left: 0;
      margin-bottom: 20px;
      .send {
        padding: 0 12px;
        height: 30px;
        border: 1px solid $button-black;
        color: $button-black;
        border-radius: 50px;
        font-size: 13px;
        transition: 0.2s ease-out;
        cursor: pointer;
        margin-left: auto;
        &:hover {
          background: $button-black;
          color: white;
        }
        &:active {
          background: $button-black-active;
          color: white;
        }
      }
    }
    .text-section {
      width: 170px;
      justify-content: space-between;
    }
  }
}

.table-wrapper {
  padding: 0 20px;
  width: 100% !important;
  .table {
    border-collapse: separate;
    border-spacing: 0 1em;
    tr {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      font-family: 'Open Sans', sans-serif;
      font-size: 13px;
      td {
        img {
          max-width: 300px;
          cursor: pointer;
        }
        p {
          img {
            max-width: 200px;
          }
        }
      }
      td:first-child {
        display: block;
        width: 100%;
        text-align: left;
        font-weight: 600;
        text-transform: capitalize;
        margin-bottom: 5px;
        border: 0 !important;
      }
      td:last-child {
        width: 100%;
        text-align: left;
        min-height: 30px;
        border-radius: 8px;
        border: 1px solid #e4e5e6;
        padding: 5px 15px !important;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        overflow: auto;
        &.reformats {
          display: flex;
          button {
            font-style: italic;
            margin-left: 10px;
            background-color: transparent;
            border: 0;
          }

          .reformat-sizes {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            .list {
              margin-right: 15px;
              padding: 0 !important;
              list-style: none;
              margin-bottom: 15px;
              width: 130px;
            }
            .list-header {
              font-weight: 700;
            }
          }
        }
        &.logotype-value {
          padding: 5px 0px !important;
        }
      }
    }
  }
}

.addfile-content {
  width: 100%;
  padding: 15px;
}

.image-list-wrapper {
  position: relative;
  margin-top: 41px;
  .actions {
    display: flex;
    justify-content: space-between;
    button {
      background-color: transparent;
      border-radius: 50px;
      padding: 4px 12px;
      text-align: center;
      font-family: 'Open Sans', sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      &.send {
        border: none;
        outline: none;
        background-color: $main-green;
        color: #fff;
      }
      &.remove {
        border: none;
        outline: none;
        background-color: $danger;
        height: 30px;
        border-radius: 50px;
        width: 100%;
        color: #fff;
        margin-right: 25px;
        &:hover {
          background: $danger-hover;
        }
        &:disabled {
          background-color: #e6e6e6;
          pointer-events: none;
        }
      }

      &.user_can_accept {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
  ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 10px;
    justify-content: space-between;

    li {
      margin-bottom: 8px;
      margin-bottom: 18px;
      width: 100%;
      max-width: 333px;
      max-height: 95px;
      display: flex;
      align-items: center;
      .index {
        color: $black;
        font-family: 'Open Sans', sans-serif;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px; /* 153.846% */
        margin-right: 10px;
      }
      h6 {
        font-family: 'Open Sans', sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 10px;
        line-height: 12px;
        text-transform: uppercase;
        color: $black;
      }
      .row {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 10px;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
        padding: 11px;
        height: 95px;
        .img-preview {
          margin-right: 8px;
          width: 150px;

          img {
            max-width: 150px;
            max-height: 50px;
            cursor: pointer;
          }
        }
        .name {
          font-family: 'Open Sans', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          display: flex;
          align-items: center;
          color: $black;
          white-space: nowrap;
        }
        .image-actions {
          display: flex;
          flex-direction: column;
          margin-right: 15px;
          input {
            width: 0;
            height: 0;
            opacity: 0;
          }
        }
        .icon {
          height: 13px;
          width: 13px;
          margin-bottom: 10px;
          cursor: pointer;
        }
      }
    }
  }
}

.file-content {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  .file-upload-label {
    color: #303a46;
    display: flex;
    flex-direction: column;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    text-align: center;
  }
  .add-file-wrapper {
    margin-top: 39px;
    width: 100%;
    height: 95px;
    background: #ffffff;
    border: 1px solid $border-grey;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    margin-bottom: 20px;
    .files-counter {
      color: $main-green;
      font-size: 14px;
      font-weight: 700;
    }
    .file-wrapper-input {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border: 2px solid red;
      z-index: 8;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
    p {
      margin: 12px;
    }
    .add-file-img {
      height: 30px;
      margin-bottom: 15px;
    }
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      height: 24px;
      width: 85px;
      background: #ffffff;
      border: 0.7px solid #ffd600;
      border-radius: 5px;

      font-family: 'Open Sans', sans-serif;
      font-style: normal;
      font-weight: 900;
      font-size: 8px;
      line-height: 10px;
      /* identical to box height */

      text-align: center;

      color: #ffd600;
      img {
        height: 10px;
        margin-left: 5px;
      }
    }
  }
}

@media (max-width: 1700px) {
  .title-height {
    height: auto;
  }
}

@keyframes rotate-border {
  to {
    transform: rotate(-360deg);
  }
}
