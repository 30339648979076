.loader-spinner {
  width: 10px;
  height: 10px;
  border: 1px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader {
  &.absolute-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  &.animate {
    animation: spin 2s linear infinite;
  }

  &.refresh_loader {
    cursor: pointer;
  }

  &.refresh_loader,
  &.action_loader {
    width: 25px;
    height: 25px;
    margin: 0 10px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-image: url('../assets/loading.svg');
  }

  &.data_loader,
  &.data_loader:before,
  &.data_loader:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: load7 1.8s infinite ease-in-out;
    animation: load7 1.8s infinite ease-in-out;
  }

  &.data_loader {
    position: absolute;
    top: 40%;
    left: 50%;
    z-index: 1;
    color: #d9d9d9;
    font-size: 10px;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
    }
    &:before {
      left: -3.5em;
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }
    &:after {
      left: 3.5em;
    }
  }
}

.loader-bgc {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 0;
  opacity: 0.5;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
