.tox-tinymce {
  border-radius: 5px !important;
}
.dropdown-list {
  z-index: 99999999;
  position: absolute;
  background: #ffffff;
  border: 1px solid #e4e5e6;
  border-radius: 8px;
  &__container {
    margin: 0;
    max-height: 200px;
    padding: 5px !important;
    overflow-y: scroll;
    overflow-x: hidden;

    min-width: 250px !important;
  }
  &__element {
    position: relative;

    padding: 0 !important;
  }

  label {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    cursor: pointer;
    margin: 0;
    font-weight: 300 !important;
  }
  input[type='checkbox'] {
    margin-right: 5px;
    accent-color: #333333;
    width: 13px;
    height: 13px;
  }

  &__checkmark {
    position: relative;
    display: block;
    height: 12px !important;
    width: 12px !important;
    background: #ffffff;
    border: 0.5px solid #d9d9d9;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
    margin-right: 20px !important;
  }
  &__radio {
    position: relative;
    display: block;
    height: 12px !important;
    width: 12px !important;
    border-radius: 100px;
    background: #ffffff;
    border: 0.5px solid #d9d9d9;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
    margin-right: 20px !important;
  }
  input[type='checkbox']:checked ~ &__checkmark {
    background: unset;
    border: 1px solid $main-green;
    box-shadow: none !important;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 7px;
      height: 7px;
      display: block;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      background: url('../assets/checkbox.svg');
    }
  }
  input[type='checkbox']:checked ~ &__radio {
    background: unset;
    box-shadow: none !important;
    &::after {
      content: '';
      position: absolute;
      left: 2px;
      top: 50%;
      transform: translateY(-50%) translateX(-7%);
      width: 7px;
      height: 7px;
      display: block;
      background-color: $main-green;
      border-radius: 100px;
    }
  }
}
